export const CONSOLE_LOG = false;
export const MEETING_LINK = "https://meetings.hubspot.com/technoava";
export const PHP_SERVER_URL = "https://host.safetytraining.education";
export const COOKIE_API_KEY = "cc08d344-5c91-4ad8-91a5-2c2f9486ac95";

export const companyData = {
    company: {
        name: "Technoava Technologies",
        short_description: "Empowering businesses with innovative digital solutions, transforming ideas into impactful web, mobile, and AI experiences.",
        description: "We are leading provider of IT-related services, offering end-to-end solutions for website development, mobile app development, API development, AI chatbot creation, logo design, and custom CMS website development. We focus on delivering high-class services at competitive rates with continuous development packages for agencies and companies.",
        services: [
            {
                name: "Website Development",
                description: "We build responsive, SEO-friendly websites tailored to your business needs."
            },
            {
                name: "Mobile App Development",
                description: "Custom mobile applications for both Android and iOS platforms, ensuring seamless user experiences."
            },
            {
                name: "API Development",
                description: "Robust API development services that integrate seamlessly with your existing systems."
            },
            {
                name: "AI Chatbot Development",
                description: "Smart AI chatbot solutions to automate customer interactions and enhance user engagement."
            },
            {
                name: "Logo Design",
                description: "Creative logo designs that align with your brand identity and vision."
            },
            {
                name: "Custom CMS Website Development",
                description: "Custom-built CMS platforms to give you full control over your website’s content and features."
            }
        ],
        key_highlights: [
            {
                id: 1,
                iconClass: "fas fa-code",
                title: "Comprehensive Development Solutions",
                description: "We offer a range of development services, from websites to mobile apps, ensuring your business's digital presence is solid."
            },
            {
                id: 2,
                iconClass: "fas fa-check-circle",
                title: "Affordable, High-Quality Services",
                description: "Get premium services at competitive prices, ensuring the best value for your investment."
            },
            {
                id: 3,
                iconClass: "fas fa-infinity",
                title: "Ongoing Maintenance and Support",
                description: "We provide continuous development and maintenance packages to ensure your systems stay updated and secure."
            },
            {
                id: 4,
                iconClass: "fas fa-server",
                title: "Full Hosting and Server Solutions",
                description: "Complete hosting and server management, keeping your systems running smoothly without any downtime."
            },
            {
                id: 5,
                iconClass: "fas fa-project-diagram",
                title: "Effective Project Management",
                description: "Advanced project management tools for seamless coordination, ensuring on-time and transparent service delivery."
            }
        ],
        contact_info: {
            email: "connect@technoava.com",
            phone: "+919775444911",
            office_address: "New Town, Kolkata - 700135, WB, India",
            address: "Dakhsin Raipur, Nodakhali, South 24 Paraganas, West Bengal, India, 743318"
        },
        social_media: [
            { iconClass: "fa-brands fa-x-twitter", href: "#" },
            { iconClass: "fa-brands fa-facebook-f", href: "#" },
            { iconClass: "fa-brands fa-instagram", href: "#" },
            { iconClass: "fa-brands fa-github", href: "#" },
            { iconClass: "fa-brands fa-linkedin", href: "#" }
        ]
    }
};
  
export const menuData = [
    {
        label: "Home",
        link: "../",
    },
    {
        label: "Services",
        link: "../services/",
    },
    {
        label: "Portfolio",
        link: "../portfolio/",
    },
    {
        label: "About us",
        link: "../about.html",
    },
];

export const footerData = {
    description: companyData.company.short_description,
    email: companyData.company.contact_info.email,
    socialMedia: [],
    subMenu: [
        {
            title: "Navigation",
            links: [
                { text: "Home", url: "../" },
                { text: "Services", url: "./services/" },
                { text: "Portfolio", url: "./portfolio/" },
                { text: "About Us", url: "../about.html" },
                { text: "Contact", url: "./contact-us/" },
            ]
        },
        {
            title: "Services",
            links: [
                { text: "Web", url: "./services/web-development/" },
                { text: "Mobile App", url: "./services/mobile-app-development/" },
                { text: "API", url: "./services/api-development/" },
                { text: "Custom AI Chatbot", url: "./services/ai-chatbot-development/" },
                { text: "Managed Platforms", url: "./services/managed-platforms-development/" },
            ]
        },
        {
            title: "Quick Links",
            links: [
                { text: "Knowledge Centre", url: `./blog/` },
                { text: "Privacy policy", url: "./legal/privacy-policy/" },
                { text: "Terms & Conditions", url: "./legal/terms-and-conditions/" },
                { text: "Shipping Policy", url: "./legal/shipping-policy/" },
                { text: "Cancellation/Refund Policy", url: "./legal/cancellation-and-refund-policy/" },
            ]
        }
    ]
};

export const testimonials = [
    {
        id: 1,
        category: "Complete Development Solution",
        name: "Jenith Israel",
        designation: "Lead Pastor, City Living Assembly",
        feedback: "Working with Technoava has been a breath of fresh air. Their team took over all the technical aspects, allowing us to focus on our mission. The flexibility and lack of long-term commitments made it a perfect fit for our church community.",
        picture: "https://technoava.com/resources/testimonials-icon/testimonials-1.jpg",
        location: "India",
        rating: 5,
    },
    {
        id: 2,
        category: "Complete Development Solution",
        name: "Debdutta Sarkar",
        designation: "Director, Hope Home Calcutta",
        feedback: "As the director of Hope Home, I needed a trustworthy partner to manage our website and digital outreach. Technoava provided seamless service with their monthly plan. No headaches, no hidden fees—just pure professionalism.",
        picture: "https://technoava.com/resources/testimonials-icon/testimonials-2.jpg",
        location: "India",
        rating: 4.6,
    },
    {
        id: 3,
        category: "Complete Development Solution",
        name: "Grace Israel",
        designation: "Principal, London Kids NewTown",
        feedback: "Launching our pre-school’s online presence felt overwhelming until we found Technoava. They handled everything from design to deployment. Their comprehensive management is exactly what we needed.",
        picture: "https://technoava.com/resources/testimonials-icon/testimonials-3.jpg",
        location: "India",
        rating: 4.7,
    },
];

export const BROCHURE_LINK = {
    default: "https://technoava.com/resources/Technoava%20Portfolio%20Brochure.pdf",
}

